import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth.guard';


const routes: Routes = [
  { path: 'dashboard', loadChildren: () => import('./page/page.module').then(m => m.PageModule)  },
  { path: 'dashboard/centre-dashboard', loadChildren: () => import('./population/population.module').then(m => m.PopulationModule),canActivate: [AuthGuard] },
  { path: 'dashboard/hospital-dashboard', loadChildren: () => import('./hospital/hospital.module').then(m => m.HospitalModule),canActivate:[AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
