import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth.guard';


const routes: Routes = [
  { path: 'developer-tools/json-tool', loadChildren: () => import('./json-tool/json-tool.module').then(m => m.JsonToolModule), canActivate:[AuthGuard] },
  { path: 'developer-tools/simple-migration-tool', loadChildren: () => import('./simple-migration-tool/simple-migration-tool.module').then(m => m.SimpleMigrationToolModule),  canActivate:[AuthGuard] },
  { path: 'developer-tools/json-file', loadChildren: () => import('./json-file/json-file.module').then(m => m.JsonFileModule) , canActivate:[AuthGuard] },
  { path: 'developer-tools/app-component', loadChildren: () => import('./app-component/app-component.module').then(m => m.AppComponentModule), canActivate:[AuthGuard] },
  { path: 'developer-tools/config-builder', loadChildren: () => import('./config-builder/config-builder.module').then(m => m.ConfigBuilderModule), canActivate:[AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DeveloperToolsRoutingModule {}
