import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { timer } from 'rxjs';
import { CentralService } from '@@sf/central-service';
import { retryWhen, delayWhen, scan } from 'rxjs/operators';

//jquery
declare var $: any;


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(    
    public app:CentralService,
    public router:Router,
    ) {
  }

  canActivate(    
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot){

    //remove the back
    this.app.removeAllModalBackdrop();
    //end
    
    let option = this.app.defautOption();                
    let retryCount = 0;    
    this.app.httpGet('/api/validate-token',option)
    .pipe(
      retryWhen( error => 
        error.pipe(     
          scan(()=>{                        
            retryCount++;
            
            if(retryCount < this.app.retryCount){
              return retryCount;
            }
            else{            
              throw(error);
            }
          }),    
          delayWhen(()=> timer(1000))
        )
      )
    )
    .subscribe(
      (data)=>{                                        
      },
      (error)=>{   
        this.app.hidespinner();                                       
        this.app.alert('alert.sessionExpired',null,()=>{          
          this.router.navigate(['/login']);          
        });                             
      }      
    );

    /* don't delete it */
    /* sidebar will be shown in all pages that is not login page */  
    setTimeout(() => {
      $('#sidebar').css('visibility','visible');                
    }, 1000);  
    
    /* end */

    /* clear the modal stack when access to the route */
    this.app.modalStack = [];

    return true;   

  }
  
}
