import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth.guard';

import { HospitalMgmtComponent } from './hospital-mgmt.component';

const routes: Routes = [
  { path: '', component: HospitalMgmtComponent }, 
  { path: 'hospital-mgmt/patient', loadChildren: () => import('./patient/patient.module').then(m => m.PatientModule),canActivate:[AuthGuard] }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HospitalMgmtRoutingModule { }
