import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth.guard';

import { MasterDataComponent } from './master-data.component';

const routes: Routes = [
  { path: '', component: MasterDataComponent }, 
  { path: 'master-data/centre', loadChildren: () => import('./centre/centre.module').then(m => m.CentreModule), canActivate:[AuthGuard] },
  { path: 'master-data/building', loadChildren: () => import('./building/building.module').then(m => m.BuildingModule) ,canActivate:[AuthGuard] }, 
  { path: 'master-data/floor', loadChildren: () => import('./floor/floor.module').then(m => m.FloorModule),canActivate:[AuthGuard] },
  { path: 'master-data/room', loadChildren: () => import('./room/room.module').then(m => m.RoomModule), canActivate:[AuthGuard]},
  { path: 'master-data/bed', loadChildren: () => import('./bed/bed.module').then(m => m.BedModule),canActivate:[AuthGuard] },  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MasterDataRoutingModule { }
