import { Component, OnInit, ComponentFactoryResolver, ɵCompiler_compileModuleSync__POST_R3__ } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CentralService } from '@@sf/central-service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';

//jquery
declare var $: any;

//Swal
import Swal from 'sweetalert2';

@Component({
  selector: 'app-yogi-mgmt',
  templateUrl: './yogi-mgmt.component.html',
  styleUrls: ['./yogi-mgmt.component.scss']
})
export class YogiMgmtComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
