import { Component, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CentralService } from '@@sf/central-service';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, Subscription, fromEvent } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SfInitAppService } from './sf-init-app.service';

//jquery
declare var $: any;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {    
  menus: any;

  /* internet connection */
  public onlineEvent: Observable<Event>;
  public offlineEvent: Observable<Event>;
  public subscriptions: Subscription[] = [];

  loginUser:any;
  
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    var key = event.key;    
    
    /* Ctrl + Alt + l (log out) */
    if(event.ctrlKey == true && event.altKey == true && key == "l"){      
      this.app.showSpinner();      
      $('#sidebar').css('visibility','hidden');    
      setTimeout(() => {        
        this.router.navigate(['/login']);      
        this.app.hidespinner();
      }, 1000);      
    }  

    /* Ctrl + Alt + n (new task) */
    if(event.ctrlKey == true && event.altKey == true && key == "n"){      
       this.newTask(); 
    }  
    
    if(key == "Escape"){       
      if(this.app.allowClosingModalWithEscapeKeyFlag){        
        this.app.removeModalFromStack(); 
      }             
    }   
  }

  newTask(){
    //exporting url
    let urlArray = this.router.url.split('/');
    urlArray[0] = '#';
    let url = this.app.env.appUrl;    
    urlArray.forEach(str => {
      url = url.concat('/',str);
    });   
    //end
    
    if(this.app.template.newTask.type == "Window"){
      window.open(url, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,width="+screen.width+",height="+screen.height);   //new window
    }
    else if(this.app.template.newTask.type == "Tab"){
      window.open(url); //new tab
    }            
  }

  constructor(
    public translate: TranslateService,
    public app:CentralService,
    public router:Router,
    public http:HttpClient,
    public sfInitApp:SfInitAppService,
    public route:ActivatedRoute
    ) {
      this.sfInitApp.initialization(this.app);
  }

  async ngOnInit() {              
    // $(document).bind('contextmenu',function() { return false; });    

    /* 
      when click on body, will hide the sidebar
    */
    $('body').on('click',(e)=>{      
      let sidebar = $('#accordionSidebar');
      let sidebarToggle = $('#sidebarToggle');
      if( !sidebar.is(e.target) && sidebar.has(e.target).length == 0 && !sidebarToggle.is(e.target) && sidebarToggle.has(e.target).length == 0){
        this.app.closeSidebar();
      }      
    })
    /* end */

    this.app.closeSidebar();

    /* initialize template setting */
    if(this.app.getTemplate() != undefined){
      let template = this.app.getTemplate();
      this.app.template = template;      
    }
    /* end */

    /* initialize language setting */
    let lang = await this.app.getStorage('cl');      
    
    if(lang == undefined || lang == ""){      
      this.app.useLang({
        id:'pyi-daung-su',
        name:""
      });      
      $('#page-top').addClass('pyi-daung-su');
    }
    else{      
      this.app.useLang(lang);    
      $('#page-top').addClass(lang.id);
    }
    /* end */   
        
    this.menus = await this.http.get(this.app.env.assetsPath + '/app/sidebar.json').toPromise();
    this.menus = this.menus.menus;            
  
    this.onOpenSidebar();

    /* internet connection */
    this.app.connectionStatus = navigator.onLine? true:false;
    if(this.app.connectionStatus == false){
      this.internetConnectionAlert();
    }

    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');
      
    this.subscriptions.push(this.onlineEvent.subscribe(e => {          
        this.app.connectionStatus = true;
        this.internetConnectionAlert();
    }));
    this.subscriptions.push(this.offlineEvent.subscribe(e => {
        this.app.connectionStatus = false;          
        this.internetConnectionAlert();
    }));
    /* end */

    /* get client info */
      let clientInfo = this.app.getStorage('ct');      
      if(clientInfo){
        let info = this.app.httpPost('/api/get-client-by-id',{id:clientInfo.id},this.app.defautOption()).toPromise();        
        this.app.setStorage('ct',
          {
            id : info[0].id,
            cn : info[0].client_name,
            ct : btoa(info[0].client_token)
          }
        );
      }
    /* end */

    /* get login user info */
    let data = this.app.getStorage('lu');   
    
    
    /* check registration */
      this.app.httpPost('/api/check-expiration',null,this.app.defautOption()).toPromise().then(
        (dateDiff:any)=>{          
          if(dateDiff.days  >  0 && dateDiff.days <= 10 && dateDiff.invert == 0){  
            let config = {
              icon  : 'info',
              title : "Expiration",
              text  : `Your application will expire in ${dateDiff.days} days.`,
              confirmButtonText : "OK",
              showConfirmButton: true,
              allowOutsideClick : false,
            }     

            this.app.alert(null,config);  
          }
          else if(dateDiff.days <= 0 || dateDiff.invert == 1){
            this.app.alert('alert.app-is-expired',{},()=>{               
              location.reload();                                         
            });
            
          }
        }
      )
    /* end */
  }

  navigate(route,prefixPath){        
    this.router.navigate([route],{queryParams:{prefix:prefixPath}});
    this.app.closeSidebar();
  }

  onOpenSidebar(){
    let token = this.app.getStorage('token');    
    if(token != undefined && token != null && token != "" ){    
      let body = {};
      let option = this.app.defautOption();        
      this.app.httpPost('/api/get-login-user-with-menu-submenu-access',body,option)
      .subscribe(
        (data) => {                          
              
          if(data['status'] == 'normal'){               
            $('#developer-tools').css('display','none');       
          }
          else{          
            $('#developer-tools').css('display','block');
          }        
      

          /* hide menus */
          let generatedMenuIds = data['menus'];
                
          generatedMenuIds.forEach(element => {           
            let menu_element = $('#' + element.generated_menu_id)
            if(element.menu_access == "false"){                        
              if(menu_element != null && menu_element != undefined){
                menu_element.css('display','none');                                  
              }            
            }        
            else{
              if(menu_element != null && menu_element != undefined){
                menu_element.css('display','block');                                                
              }                                    
            }          
          });
          /* end */
          
          /* hide submenus */
          let generatedSubmenuIds = data['submenus'];            
          generatedSubmenuIds.forEach(element => {
            let submenu_element = $('#' + element.generated_submenu_id);
            if(element.menu_access == "false"){   
              if(submenu_element != null && submenu_element != undefined){
                submenu_element.css('display','none');
              }            
                                    
            }                    
            else{ 
              if(submenu_element != null && submenu_element != undefined){
                submenu_element.css('display','block');
              }              
                              
            }
          });
          /* end */        
        },
        (error) => {                
          // this.app.commonError(error);
        }
      ) 
    }   
  }

  internetConnectionAlert(){    
    let messageKey = '';
    let icon = '';
    let allowOutsideClick = true;
    let showConfirmButton = true;

    if(this.app.connectionStatus == true){      
      icon = 'success';
      messageKey = 'alert.connectionOnline';
      allowOutsideClick = true;
      showConfirmButton = true;
      
    }
    else{      
      icon = 'error';
      messageKey = 'alert.connectionOffline';
      allowOutsideClick = false;
      showConfirmButton = false;
    }
    this.app.getTranslationValueWithKey(messageKey).subscribe(
      (message) => {
        let config = {
          icon  : icon,
          title : message.title,
          text  : message.text,
          confirmButtonText : message.confirmButtonText,
          showConfirmButton: showConfirmButton,
          allowOutsideClick : allowOutsideClick,
        }                
        this.app.alert(null,config);        
      }
    );    
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }


}
