import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HospitalMgmtRoutingModule } from './hospital-mgmt-routing.module';
import { HospitalMgmtComponent } from './hospital-mgmt.component';


@NgModule({
  declarations: [HospitalMgmtComponent],
  imports: [
    CommonModule,
    HospitalMgmtRoutingModule
  ]
})
export class HospitalMgmtModule { }
