
<div id="sidebar" style="visibility: hidden;">
  <!-- Page Wrapper -->
  <div id="">  
    <!-- Sidebar Toggler (Sidebar) -->
    <div class="text-center">
      <button [class]="'rounded shadow border-0 border-none ' + this.app.template.sidebar.backgroundColor" id="sidebarToggle" (click)="this.app.toggleSidebar()">
        <i [class]="this.app.toogleIcon + ' ' + this.app.template.sidebar.textColor"></i>
      </button>
    </div>

    <!-- Sidebar -->
    <ul  [class]="'navbar-nav sidebar sidebar-dark accordion sidebar-close ' + this.app.template.sidebar.backgroundColor + ' ' +  this.app.template.sidebar.textColor" id="accordionSidebar">

      <!-- Sidebar - Brand -->
      <li class="nav-item">
        <a [class]="'p-2 d-flex align-items-center justify-content-center ' + this.app.template.sidebar.textColor">
          <div class="w-100 d-flex justify-content-center flex-column text-center font-weight-bold">
            <span style="font-size: 22px;">{{ this.app.info.name }}</span>  
            <span *ngIf="this.app.getStorage('lu')">{{ this.app.getStorage('lu').centre }}</span>
          </div>
        </a>
      </li>
      
      <hr class="sidebar-divider my-0 bg-secondary">

      <li class="nav-item">
        <div class="d-flex p-2" *ngIf="this.app.getStorage('lu')">
          <img class="ml-4 mr-2 img-profile rounded" [src]="app.env.apiHost + '/' + this.app.getStorage('lu').image" style="width:75px;height:75px; box-shadow: 0 8px 6px -6px black;">
  
          <div class="w-100 d-flex justify-content-center flex-column text-center font-weight-bold">
            <span>{{ this.app.getStorage('lu').name }}</span>          
            <span>{{ this.app.getStorage('lu').position }}</span>            
          </div>
        </div>                  
      </li>
      

      <hr class="sidebar-divider my-0 bg-secondary">
      
      <!-- Nav Item - Dashboard -->
      <li class="nav-item mt-2" *ngFor="let menu of menus; let menuIndex = index" id="{{ menu.title=='Developer-Tools'? 'developer-tools': menuIndex }}">
        <div *ngIf="menu.type == 'menuOnly'">
          <a [class]="'w-100 nav-link ' + this.app.template.sidebar.textColor" (click)="navigate(menu.route,menu.prefixPath)" [routerLink]="[menu.route]" routerLinkActive="sf-sidebar-link-active">
            <i [class]="menu.icon"></i>
            <span class="pl-1">{{ menu.title | translate}}</span>
          </a>
        </div>
        <div *ngIf="menu.type == 'menuWithSubMenus'">
          <a [class]="'w-100 nav-link ' + this.app.template.sidebar.textColor + ' collapsed'" data-toggle="collapse" [attr.data-target]="'#menu-'+ menuIndex" aria-expanded="false" aria-controls="collapsePages">
            <i [class]="menu.icon"></i>
            <span class="pl-1">{{ menu.title | translate}}</span>
          </a>
          <div id="menu-{{ menuIndex }}" class="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
            <div class="bg-transparent py-2 collapse-inner rounded">                        
              <a [class]="'collapse-item ' + this.app.template.sidebar.textColor + ' ' + this.app.template.sidebar.submenuBorderClass"  (click)="navigate(submenu.route,submenu.prefixPath)" [routerLink]="[submenu.route]"  routerLinkActive="sf-sidebar-link-active"  *ngFor="let submenu of menu.submenus; let submenuIndex = index" id="{{menu.title=='Developer-Tools'? '': menuIndex + '-' + submenuIndex }}">{{ submenu.title | translate }}</a>              
            </div>
          </div>
        </div>        
      </li>         

      <div style="margin-bottom:50vh;">

      </div>

    </ul>
    <!-- End of Sidebar -->
        
  </div>
  <!-- End of Page Wrapper -->
</div>

<router-outlet></router-outlet>

<!-- <ngx-spinner></ngx-spinner> -->
<ngx-spinner name="app-spinner" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" [type]="this.app.template.spinner.type" [fullScreen]="true"><p style="color:white;" class="text-center mt-1">{{ this.app.template.spinner.message | translate}} </p></ngx-spinner>



