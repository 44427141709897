import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth.guard';

const routes: Routes = [
  { path: 'settings', loadChildren: () => import('./page/page.module').then(m => m.PageModule), canActivate:[AuthGuard]},
  { path: 'settings/change-template-color', loadChildren: () => import('./change-template-color/change-template-color.module').then(m => m.ChangeTemplateColorModule), canActivate:[AuthGuard] },
  { path: 'settings/change-language', loadChildren: () => import('./change-language/change-language.module').then(m => m.ChangeLanguageModule), canActivate:[AuthGuard]},
  { path: 'settings/change-spinner', loadChildren: () => import('./change-spinner/change-spinner.module').then(m => m.ChangeSpinnerModule), canActivate:[AuthGuard] },
  { path: 'settings/change-page-view', loadChildren: () => import('./change-page-view/change-page-view.module').then(m => m.ChangePageViewModule), canActivate:[AuthGuard]},
  { path: 'settings/change-password-and-passcode', loadChildren: () => import('./change-password-and-passcode/change-password-and-passcode.module').then(m => m.ChangePasswordAndPasscodeModule) , canActivate:[AuthGuard]},
  { path: 'settings/change-managed-centre', loadChildren: () => import('./change-managed-centre/change-managed-centre.module').then(m => m.ChangeManagedCentreModule),canActivate:[AuthGuard] },
  { path: 'settings/change-new-task-type', loadChildren: () => import('./change-new-task-type/change-new-task-type.module').then(m => m.ChangeNewTaskTypeModule) ,canActivate:[AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingRoutingModule { }
