import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { YogiMgmtRoutingModule } from './yogi-mgmt-routing.module';
import { YogiMgmtComponent } from './yogi-mgmt.component';


@NgModule({
  declarations: [YogiMgmtComponent],
  imports: [
    CommonModule,
    YogiMgmtRoutingModule
  ]
})
export class YogiMgmtModule { }
