import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelpersRoutingModule } from './helpers-routing.module';



@NgModule({
  declarations: [    

  ],
  imports: [        
    CommonModule,
    HelpersRoutingModule
  ]
})
export class HelpersModule { }
