import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth.guard';

const routes: Routes = [
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule)},
  { path: 'list/:name', loadChildren: () => import('./generate-list-form/generate-list-form.module').then(m => m.GenerateListFormModule),canActivate: [AuthGuard] },
  { path: 'panels/:name', loadChildren: () => import('./generate-panel-form/generate-panel-form.module').then(m => m.GeneratePanelFormModule), canActivate: [AuthGuard] },  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HelpersRoutingModule { }
