import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth.guard';


const routes: Routes = [
  { path: 'account-security/role-access', loadChildren: () => import('./role-access/role-access.module').then(m => m.RoleAccessModule) , canActivate:[AuthGuard] },
  { path: 'account-security/menu-submenu-access', loadChildren: () => import('./menu-submenu-access/menu-submenu-access.module').then(m => m.MenuSubmenuAccessModule), canActivate:[AuthGuard] },
  { path: 'account-security/user-account', loadChildren: () => import('./user-account/user-account.module').then(m => m.UserAccountModule), canActivate:[AuthGuard] },
  { path: 'account-security/client', loadChildren: () => import('./client/client.module').then(m => m.ClientModule), canActivate:[AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountSecurityRoutingModule { }
