import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth.guard';

import { YogiMgmtComponent } from './yogi-mgmt.component';

const routes: Routes = [
  { path: '', component: YogiMgmtComponent },
  { path: 'yogi-mgmt/yogi', loadChildren: () => import('./yogi/yogi.module').then(m => m.YogiModule), canActivate:[AuthGuard] },
  { path: 'yogi-mgmt/share-report', loadChildren: () => import('./share-report/share-report.module').then(m => m.ShareReportModule), canActivate:[AuthGuard] },
  { path: 'message-center', loadChildren: () => import('./message-center/message-center.module').then(m => m.MessageCenterModule), canActivate:[AuthGuard] }, 
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class YogiMgmtRoutingModule { }
